import React, { useState, useEffect } from "react";
import './style.css';
import { useParams,useHistory } from "react-router-dom";
function Navbar() {
  const history = useHistory();
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
      <div id="navbar" className={`fixed top-0 left-0 right-0 bg-[#f5f2e9] shadow-md z-20 transition-transform duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'}`}
      >
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex items-baseline ml-5 md:ml-20" onClick={() => history.push('/')}>
            <h1 className="text-2xl font-bold text-[#004d40] tracking-10">ASHLEY</h1>
            <span className="text-md text-[#004d40] ml-1 tracking-widest">Career</span>
          </div>
          <div className="hidden text-base md:flex space-x-8 mr-5">
            <a href="/" className="text-gray-600 tracking-widest transition-transform duration-300 hover-3d">Home</a>
            <a href="/vacancy/list" className="text-[#004d40] tracking-widest transition-transform duration-300 hover-3d">Jobs</a>
           
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsOpenNav(!isOpenNav)} className="text-gray-600 focus:outline-none">
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </div>
        {isOpenNav && (
          <div className="md:hidden z-10 flex flex-col space-y-2 p-4">
            <a href="/" className="text-gray-600 tracking-widest transition-transform duration-300 hover-3d">Home</a>
            <a href="/vacancy/list" className="text-[#004d40] tracking-widest transition-transform duration-300 hover-3d">Jobs</a>
            {/* <a href="#" className="text-gray-600 tracking-widest transition-transform duration-300 hover-3d">Our Brands</a> */}
          </div>
        )}
      </div>
      <br />
      <br />
    </>
  );
}

export default Navbar;