import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import './style.css';
import axios from "axios";
import Swal from "sweetalert2";
import noAva from '../assets/no-avatar.jpg';
import { useParams, useHistory } from "react-router-dom";
import Toast from "../components/Alert";
import ReCAPTCHA from 'react-google-recaptcha';  
import Select from "react-select";
function VacancyForm() {

    const history = useHistory();
    const { idVac, noVac } = useParams();
    const [device, setDevice] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [vacancyDetail, setVacancyDetail] = useState([]);
    const [vacancyQuest, setVacancyQuest] = useState([]);
    const [currentStep, setCurrentStep] = useState(1); // Step tracking
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [toast, setToast] = useState({ show: false, message: "", type: "" });
    const [captchaValue, setCaptchaValue] = useState(null);  
    const [verified, setVerified] = useState(false);
    const [formData, setFormData] = useState({
        profilePhoto: null,
        gender: "",
        dob: "",
        fullName: "",
        email: "",
        phone: "",
        currentLocation: "",
        currentCompany: "",
        linkedin: "",
        resume: null,
    });
    const [formScreening, setFormScreening] = useState([]);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const onChange = (value) => {  
        setCaptchaValue(value);  
    };

    const handleToastClose = () => {
        setToast({ show: false, message: "", type: "" });
    };

    useEffect(() => {
        const handleDeviceDetection = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
            const isTablet = /(ipad|tablet|playbook|silk)|(android(?!. *mobile))/g.test(userAgent);
            if (isMobile) {
                setDevice("Mobile");
            } else if (isTablet) {
                setDevice("Tablet");
            } else {
                setDevice("Desktop");
            }
        };
        handleDeviceDetection();

        const fetchIpAndLocation = async () => {
            try {
                const response = await axios.get("https://ipapi.co/json/");
                const { ip, city, region, country_name } = response.data;
                
                setIpAddress(ip);
                // Set the default value for the `currentLocation` field
                setFormData((prevData) => ({
                    ...prevData,
                    currentLocation: city || "",
                }));
                console.log(`Location: ${city}, ${region}, ${country_name}`);
            } catch (error) {
                console.error("Error fetching IP and location:", error);
            }
        };
        
        fetchIpAndLocation();

        const getDetailVacancy = async (idVac, noVac) => {
            try {
                const response = await axios.get(`https://apicareer.ashleyhotelgroup.co.id/api/get/vacancy/${idVac}/${noVac}`);
                if (response.status === 200) {
                    console.log(response.data.vacancyQuest)
                    setVacancyDetail(response.data.vacancyDetail);
                    setVacancyQuest(response.data.vacancyQuest);
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 404) {
                        history.push("/not-found");
                    } else {
                        console.error("Error fetching vacancy:", error.response.data);
                    }
                } else {
                    console.error("Error fetching vacancy:", error.message);
                }
            }
        };

        if (idVac && noVac) {
            getDetailVacancy(idVac, noVac);
        }
    }, [idVac, noVac, history]);

    useEffect(() => {
        checkFormValidity();
    }, [formData,formScreening, errors]);

    const validateCurrentStep = () => {
        const stepErrors = {};
    
        if (currentStep === 1) {
            // Validate personal info
            if (!formData.profilePhoto) stepErrors.profilePhoto = "Profile photo is required.";
            if (!formData.gender) stepErrors.gender = "Gender is required.";
            if (!formData.dob) stepErrors.dob = "Date of birth is required.";
            if (!formData.fullName) stepErrors.fullName = "Full name is required.";
            if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) stepErrors.email = "Valid email is required.";
            if (!formData.phone || formData.phone.length > 13) stepErrors.phone = "Valid phone number (max 13 digits) is required.";
            if (!formData.currentLocation) stepErrors.currentLocation = "Current location is required.";
            if (!formData.resume) stepErrors.resume = "Resume is required.";
        } else if (currentStep === 2) {
            // Validate screening questions
            const completedScreenings = formScreening.length;
            if (completedScreenings !== vacancyQuest.length) {
                stepErrors.screening = "All screening questions must be answered.";
            }
        }
    
        // Update errors state
        setErrors(stepErrors);
    
        // Show toast based on validation result
        if (Object.keys(stepErrors).length === 0) {
            showToast("Validation successful!", "success");
            return true;
        } else {
            showToast("Validation failed. Please fix errors.", "error");
            return false;
        }
    };

    const handleNext = () => {
        if (validateCurrentStep()) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevious = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    
    // Generate year options dynamically
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const options = [];
        for (let year = currentYear; year >= 1900; year--) {
        options.push({ value: year, label: year.toString() });
        }
        return options;
    };

    const yearOptions = generateYearOptions();

    const handleYearChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            dob: selectedOption.value, // Update dob with selected year
        }));
    };
    
    const handleInputChange = (e) => {
        const { name, value,files }  = e.target;
    
        if (name === "profilePhoto") {
            const file = files[0];
    
            // Check if a file is selected
            if (file) {
                // Validate file type for profilePhoto
                const validTypes = ["image/jpeg", "image/png"];
                if (!validTypes.includes(file.type)) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        profilePhoto: "Only JPG or PNG files are allowed.",
                    }));
                    return;
                }
    
                // Validate file size (2MB = 2,097,152 bytes)
                if (file.size > 2097152) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        profilePhoto: "File size must be less than or equal to 2MB.",
                    }));
                    return;
                }
    
                // If validation passes, update the state
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: file,
                }));
    
                // Clear any previous errors for profilePhoto
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    profilePhoto: null,
                }));
            }
        } else if (name === "resume") {
            const file = files[0];
    
            // Check if a file is selected
            if (file) {
                // Validate file type for resume
                if (file.type !== "application/pdf") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        resume: "Only PDF files are allowed.",
                    }));
                    return;
                }
    
                // Validate file size (5MB = 5,242,880 bytes)
                if (file.size > 5242880) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        resume: "File size must be less than or equal to 5MB.",
                    }));
                    return;
                }
    
                // If validation passes, update the state
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: file,
                }));
    
                // Clear any previous errors for resume
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    resume: null,
                }));
            }
        } else if (name === "phone") {
            // Remove any non-numeric characters, including '+' and '-'
            const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
    
            // Validate phone number length
            if (sanitizedValue.length > 13) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Phone number must be 13 digits or less.",
                }));
                return;
            }
    
            // Clear any previous errors if validation passes
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: null,
            }));
    
            // Update the form data with sanitized value
            setFormData((prevData) => ({
                ...prevData,
                [name]: sanitizedValue,
            }));
            return;
        }
        else {
            // Handle other inputs
            setFormData((prevData) => ({
                ...prevData,
                [name]: e.target.value,
            }));
        }
    };
    
    const validateInput = (name, value) => {
        let error = "";

        switch (name) {
            case "profilePhoto":
                if (!value) {
                    error = "Profile photo is required";
                }
                break;
            case "gender":
                if (!value) {
                    error = "Gender is required";
                }
                break;
            case "dob":
                if (!value) {
                    error = "Date of birth is required";
                }
                break;
            case "fullName":
                if (!value) {
                    error = "Full name is required";
                }
                break;
            case "email":
                if (!value) {
                    error = "Email is required";
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "Email is invalid";
                }
                break;
            case "phone":
                if (!value) {
                    error = "Phone number is required";
                }
                break;
            case "currentLocation":
                if (!value) {
                    error = "Current location is required";
                }
                break;
            case "currentCompany":
                if (!value) {
                    error = "Current company is required";
                }
                break;
            case "resume":
                if (!value) {
                    error = "Resume is required";
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const checkFormValidity = () => {
        // Count the number of unique answered screenings
        const completedScreenings = formScreening.length;
    
        // Validate all form fields and screening questions
        const allFieldsValid =
            Object.values(formData).every((value) => value) && // Validate all fields in formData
            Object.values(errors).every((error) => !error) && // No validation errors
            completedScreenings === vacancyQuest.length; // Ensure all screening questions are answered
    
        // console.log("Vacancy Quest Total:", vacancyQuest.length);
        // console.log("Completed Screenings:", completedScreenings);
    
        setIsFormValid(allFieldsValid);
    };

    const handleScreeningChange = (e) => {
        const { id, name, value } = e.target;
        // Extract the numeric part of the id
        const numericId = id.split("_")[0]; // Split by "_" and take the first part
        
        setFormScreening((prevData) => {
            const existingIndex = prevData.findIndex((item) => item.id === numericId);
            if (existingIndex > -1) {
                const updatedData = [...prevData];
                updatedData[existingIndex] = { id: numericId, quest: name, ans: value };
                return updatedData;
            }
            return [...prevData, { id: numericId, quest: name, ans: value }];
        });
        console.log(formScreening)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Create FormData
            const formDataToSubmit = new FormData();
    
            // Add basic fields to FormData
            Object.entries(formData).forEach(([key, value]) => {
                if (key === "profilePhoto" || key === "resume") {
                    // Append file
                    if (value instanceof File) {
                        formDataToSubmit.append(key, value);
                    }
                } else {
                    // Append other fields
                    formDataToSubmit.append(key, value);
                }
            });
    
            // Add JSON stringified data
            formDataToSubmit.append("formScreening", JSON.stringify(formScreening));
            formDataToSubmit.append("vacancyDetail", JSON.stringify(vacancyDetail));
            formDataToSubmit.append("ipAddress", ipAddress);
    
            // Debugging: View FormData content in the console
            for (let [key, value] of formDataToSubmit.entries()) {
                console.log(`${key}:`, value);
            }

            // console.log(formDataToSubmit)

            // Send POST request
            const response = await axios.post(
                `https://apicareer.ashleyhotelgroup.co.id/api/apply/${idVac}/${noVac}`,formDataToSubmit,{headers: {"Content-Type": "multipart/form-data"}}
            );
            console.log(response.data)
            // if success 
            if(response.status === 200){
                showToast(response.data.message, "success");
                // Set a timeout to redirect after 5000 milliseconds (5 seconds)  
                setTimeout(() => {  
                    Swal.fire("Success", response.data.message, "success");
                    history.push( "/vacancy/list") // Replace with your desired URL  
                }, 1000); 
            }
            
            
        } catch (error) {
            console.error("Error submitting form:", error);
            showToast(error, "error");
        }
    };
    
    return (
        <>
            <Navbar />
           
            <div className="sticky top-0 bg-[#fdf8e4] py-10 text-center z-10">
                <h1 className="text-xl font-bold text-[#2b3a42]">{vacancyDetail.title_job}</h1>
                <p className="xl:text-xl md:text-lg lg:text-xl sm:text-sm font-semibold text-gray-600 mt-1">
                    {vacancyDetail.dept_name} <span className="font-normal">· {vacancyDetail.type_job}</span>
                </p>
                <p className="text-base text-gray-500 mt-2">
                    <i className="fas fa-map-marker-alt"></i> {vacancyDetail.hotel_name}, {vacancyDetail.loc}
                </p>
                <p className="text-sm text-gray-400 mt-2">
                    {device === 'Mobile' ? (
                        <div>
                            <i className="fas fa-mobile-alt"></i> {ipAddress}
                        </div>
                    ) : (
                        <div>
                            <i className="fas fa-desktop"></i> {ipAddress}
                        </div>
                    )}
                </p>
            </div>
            
            <div id="app" className="max-w-6xl mx-auto mt-3 p-6  rounded-lg shadow-lg mb-8 bg-[#F9F9F9]">
                <h1 className="text-xl font-semibold text-center text-gray-800 py-6">Submit Your Application</h1>
            
                <div className="flex items-center justify-between mb-6">
                    <div className="flex flex-col items-center">
                        <div className={`w-10 h-10 flex items-center justify-center rounded-full text-sm ${currentStep === 1 ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-600'}`}>
                            1
                        </div>
                        <span className={`mt-1 text-sm ${currentStep === 1 ? 'text-gray-800' : 'text-gray-600'}`}>Personal Info</span>
                    </div>

                    <div className="flex-grow border-t border-gray-300 mx- 2"></div>

                    <div className="flex flex-col items-center">
                    <div className={`w-10 h-10 flex items-center justify-center rounded-full text-sm ${currentStep === 2 ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-600'}`}>
                        2
                    </div>
                    <span className={`mt-1 text-sm ${currentStep === 2 ? 'text-gray-800' : 'text-gray-600'}`}>Application Form</span>
                    </div>

                    <div className="flex-grow border-t border-gray-300 mx-2"></div>

                    <div className="flex flex-col items-center">
                    <div className={`w-10 h-10 flex items-center justify-center rounded-full text-sm ${currentStep === 3 ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-600'}`}>
                        3
                    </div>
                    <span className={`mt-1 text-sm ${currentStep === 3 ? 'text-gray-800' : 'text-gray-600'}`}>Finish & Submit</span>
                    </div>
                </div>

                <form autoComplete="off" onSubmit={handleSubmit}>    

                    {currentStep === 1 && (    
                    <>
                        <h2 className="text-xl font-semibold text-gray-800 mb-5">Personal Info</h2>
                        
                        {/* Profile Photo Upload */}
                        <div className="mb-4 text-center">
                            <input
                                id="profile-photo"
                                type="file"
                                accept="image/*"
                                name="profilePhoto"
                                onChange={handleInputChange}
                                className="hidden"
                                required
                            />
                        
                            {formData.profilePhoto ? (
                                <img src={URL.createObjectURL(formData.profilePhoto)} alt="Profile Thumbnail" className="object-cover h-40 w-40 rounded-md mt-2 mx-auto" />
                            ) : (
                                <img src={noAva} alt="Default Avatar" className="object-cover h-20 w-20 rounded-lg mt-1 mx-auto mb-3" />
                            )}
                            <label htmlFor="profile-photo" className="block text-blue-700 mb-1 text-base cursor-pointer">*Upload Photo | (Max 5MB)</label>
                            {errors.profilePhoto && <p className="text-red-500 text-sm">{errors.profilePhoto}</p>}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">* Gender</label>
                            <select name="gender" value={formData.gender} onChange={handleInputChange} required className="w-full px-3 py-2 border border-gray-300 rounded-md text-base">
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            {errors.gender && <p className="text-red-500 text-sm mt-1">{errors.gender}</p>}
                        </div>
                       
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">Year of Birth</label>
                            <Select
                                options={yearOptions}
                                value={yearOptions.find((option) => option.value === formData.dob)}
                                onChange={handleYearChange}
                                placeholder="Year"
                                isClearable
                                classNamePrefix="react-select"
                                styles={{
                                    control: (base) => ({
                                    ...base,
                                    borderColor: errors.dob ? "red" : base.borderColor,
                                    boxShadow: errors.dob ? "0 0 0 1px red" : base.boxShadow,
                                    zIndex: 1, // Set control z-index to 1
                                    }),
                                    menu: (base) => ({
                                    ...base,
                                    zIndex: 1, // Make the dropdown menu appear behind other elements
                                    }),
                                }}
                            />

                            {errors.dob && <p className="text-red-500 text-sm mt-1">{errors.dob}</p>}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">Full Name</label>
                            <input type="text"  value={formData.fullName} autoComplete="new-fullName" name="fullName" onChange={handleInputChange} required className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                            {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
                        </div>

                        <div className="mb-4">
                            <label className=" text-gray-700 mb-1 text-base flex justify-between items-center">
                                Email
                                <span className="text-sm text-gray-500">*Please enter a valid email address (e.g., user@example.com).</span>
                            </label>
                            <input 
                                type="email" 
                                value={formData.email} 
                                autoComplete="off" 
                                name="email" 
                                onChange={handleInputChange} 
                                required 
                                className="w-full px-3 py-2 border border-gray-300 rounded-md text-base"
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                        </div>


                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">Phone</label>
                            <input type="tel" value={formData.phone} autoComplete="off"  name="phone" onChange={handleInputChange} 
                            required 
                            onKeyPress={(e) => {
                                // Prevent non-numeric characters, including '+' and '-'
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            maxLength="13" // Restrict input length to 13 characters
                            className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                            {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">Current Location</label>
                            <input type="text"  autoComplete="off"  value={formData.currentLocation} name="currentLocation" onChange={handleInputChange} required className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                            {errors.currentLocation && <p className="text-red-500 text-sm mt-1">{errors.currentLocation}</p>}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">Current Company (If Any)</label>
                            <input type="text"  autoComplete="off"  value={formData.currentCompany} name="currentCompany" onChange={handleInputChange} required className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                            {errors.currentCompany && <p className="text-red-500 text-sm mt-1">{errors.currentCompany}</p>}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">LinkedIn URL (Optional)</label>
                            <input type="url"  autoComplete="off" value={formData.linkedin} name="linkedin" onChange={handleInputChange} className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-1 text-base">*Resume / CV | (PDF Max 100mb)</label>
                            <input type="file"  autoComplete="off"  name="resume" onChange={handleInputChange} required className="w-full px-3 py-2 border border-gray-300 rounded-md text-base" />
                            {errors.resume && <p className="text-red-500 text-sm mt-1">{errors.resume}</p>}
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="button"
                                onClick={() => handleNext()}
                                className="px-4 py-2 text-base bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                                Next
                            </button>
                        </div>
                    </>  
                    )}  
                    {currentStep === 2 && (
                    <>
                        {vacancyQuest.map((item) => (
                            <div key={item.id_screening} className="mb-4">
                            <h2 className="text-gray-400 font-semibold mb-4">{item.object_}</h2>
                            <p className="text-gray-700 font-bold mb-3">{item.question_}</p>
                            {item.type_ === 1 ? (
                                <div className="flex items-center">
                                    <input
                                        onChange={handleScreeningChange}
                                        className="mr-2"
                                        type="radio"
                                        id={`${item.id_screening}_yes`}
                                        name={item.question_} // Use id for unique naming
                                        value="Yes"
                                        checked={
                                            formScreening.find(
                                                (screening) =>
                                                    screening.id == item.id_screening &&
                                                    screening.ans == "Yes"
                                            ) || false
                                        }
                                        required
                                        style={{ width: "20px", height: "20px" }}
                                    />
                                    <label htmlFor={`${item.id_screening}_yes`} className="text-gray-700 text-lg">
                                        Yes
                                    </label>
                                    <input
                                        onChange={handleScreeningChange}
                                        className="mr-2 ml-4"
                                        type="radio"
                                        id={`${item.id_screening}_no`}
                                        name={item.question_} // Use id for unique naming
                                        value="No"
                                        checked={
                                            formScreening.find(
                                                (screening) =>
                                                    screening.id == item.id_screening &&
                                                    screening.ans == "No"
                                            ) || false
                                        }
                                        required
                                        style={{ width: "20px", height: "20px" }}
                                    />
                                    <label htmlFor={`${item.id_screening}_no`} className="text-gray-700 text-lg">
                                        No
                                    </label>
                                </div>
                            ) : (
                                <input
                                    onChange={handleScreeningChange}
                                    type="number"
                                    id={item.id_screening}
                                    min={0}
                                    max={99}  // Limiting the maximum number to 99
                                    name={item.question_} // Use question for unique naming
                                    onKeyDown={(e) => {
                                        // Prevent the '-' (minus) key and any non-numeric keys
                                        if (e.key === '-' || !/[0-9]/.test(e.key)) {
                                        e.preventDefault(); // Prevent default action (typing)
                                        }
                                    }}
                                    onInput={(e) => {
                                        // Ensure the value doesn't exceed 2 digits
                                        const value = e.target.value;
                                        if (value && value.length > 2) {
                                        e.target.value = value.slice(0, 2); // Trim to 2 digits
                                        }
                                    }}
                                    value={
                                        formScreening.find(
                                        (screening) => parseInt(screening.id, 10) === item.id_screening
                                        )?.ans || ""
                                    }
                                    required
                                    className="border rounded px-2 py-1"
                                    placeholder="Number"
                                    style={{ width: "100px" }}
                                />
                            )}
                        </div>

                        ))}
                        <div className="flex justify-between"> 
                            <button type="button"  className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlePrevious}>Previous</button>
                            <button type="button"  className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleNext}>Next</button>
                        </div>
                    </>
                    )}
                    {currentStep === 3 && (
                        console.log("currentStep is 3"),
                        <>
                            <div className="flex flex-col items-center  max-h-screen bg-gray-100 px-4">
                                <div className="bg-white mt-5 mb-5 rounded-lg shadow-lg p-6 max-w-lg text-center">
                                    <img
                                        src={noAva}
                                        alt="Company Logo"
                                        className="w-24 h-24 mx-auto mb-6"
                                    />
                                    <h1 className="text-lg font-bold text-gray-800 mb-4">
                                        Thank You for Applying!
                                    </h1>
                                    <p className="text-gray-600 leading-relaxed text-base mb-4">
                                        Thank you for applying to our company. We sincerely appreciate your interest and the effort you have put into your application.
                                    </p>
                                    <p className="text-gray-600 leading-relaxed text-base mb-4">
                                        We hope that you will be invited to join our corporate family and contribute to our shared goals. Please note that all decisions made by the company are final and cannot be contested.
                                    </p>
                                    <p className="text-gray-600 leading-relaxed text-base ">
                                        We wish you the best of luck in the selection process and hope to see you as part of our team.
                                    </p>

                                    {/* Agreement Checkbox */}
                                    <div className="flex items-center mt-4">
                                        <input
                                            type="checkbox"
                                            id="agreement"
                                            className="w-4 h-4 mt-5 text-blue-600 border-gray-300 rounded"
                                            onChange={(e) => setIsAgreementChecked(!isAgreementChecked)}
                                        />
                                        <label htmlFor="agreement" className="ml-2 mt-5 mb-2 font-bold text-gray-600 text-sm">
                                            I agree to the rules and agreements
                                        </label>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <ReCAPTCHA
                                            sitekey="6Leoj48qAAAAAGiFYyJl2YICeMYE3EhiDQH3RCvx"
                                            onChange={onChange}
                                        />
                                        {verified ? (
                                            <p className="ml-4 text-green-500">ReCAPTCHA Verified!</p>
                                        ) : (
                                            <p className="ml-4 text-red-500">Please verify the ReCAPTCHA.</p>
                                        )}
                                    </div>

                                    {isFormValid && isAgreementChecked && (
                                        <button type="submit" className="mt-4 w-full bg-green-600 text-white py-2 rounded-md">Submit Application</button>
                                    )}
                                </div>

                                <div className="flex justify-between items-center w-full space-x-4 mb-2">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm bg-sky-500 text-white rounded-md hover:bg-blue-600"
                                        onClick={handlePrevious}
                                    >
                                        Previous
                                    </button>
                                    
                                </div>
                                
                            </div>

                        
                        </>
                    )}
                    
                </form>

                {toast.show && (
                    <Toast
                        message={toast.message}
                        type={toast.type}
                        onClose={handleToastClose}
                    />
                )}

            </div>
        </>
    );
}

export default VacancyForm;